import React, { useState, useEffect } from "react";
import { Table, Button, Form, Container, Row, Col,Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import moment from "moment";
import NavBar from "../Navigation bar/Navbar";
import * as XLSX from "xlsx";
import { orderingApiUrl } from "../../Configs/mysqlconfig";

function ScratchCardTransactionsReport() {
  const today = moment().format("YYYY-MM-DD");
  const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(yesterday);
  const [endDate, setEndDate] = useState(yesterday);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(()=>{
    fetchData()
  },[startDate,endDate])

 const fetchData = async () => {
    if (!startDate || !endDate) {
      toast.error("Please select both start and end dates!");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`${orderingApiUrl}/scratch-card-reports?start_date=${startDate}&end_date=${endDate}&token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik`);
      setTableData(response.data);
    } catch (error) {
      setError("Failed to load data.");
      toast.error("Error fetching data!");
    } finally {
      setLoading(false);
    }
  };

  const exportToExcel = () => {
    if (tableData.length === 0) {
      toast.error("No data available to export!");
      return;
    }
  
    const totals = calculateTotals();
  
    const exportData = [...tableData, totals];
  
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Delivery Summary");
  
    XLSX.writeFile(workbook, "transactions-report.xlsx");
  };
  

  const calculateTotals = () => {
    return tableData.reduce(
      (totals, row) => {
        totals.Total_Unique_Orders += row.Total_Unique_Orders || 0;
        totals.Total_Unique_Stores += row.Total_Unique_Stores || 0;
        totals.Sum_of_Cashback += row.Sum_of_Cashback || 0;
        return totals;
      },
      { Area_Manager: "TOTAL", Total_Unique_Orders: 0, Total_Unique_Stores: 0, Sum_of_Cashback: 0 }
    );
  };

  return (
      <>
        <NavBar />
        <Container fluid>
      <Row>
        <Col>
      <div style = {{margin:"7rem"}}>
  
      <h2 className="mt-4">Scratch Card Transactions Report</h2>

      <Row className="mb-3 d-flex align-items-end">
  <Col md={4}>
    <Form.Group>
      <Form.Label>Start Date</Form.Label>
      <Form.Control
        type="date"
        value={startDate}
        max={today}
        onChange={(e) => setStartDate(e.target.value)}
      />
    </Form.Group>
  </Col>
  <Col md={4}>
    <Form.Group>
      <Form.Label>End Date</Form.Label>
      <Form.Control
        type="date"
        value={endDate}
        max={today}
        onChange={(e) => setEndDate(e.target.value)}
      />
    </Form.Group>
  </Col>
  <Col md={4} >
  <Form.Group>
  <Button variant="success" onClick={exportToExcel}>
      Export to Excel
    </Button>
  </Form.Group>
   
  </Col>
</Row>
      
      <div className="table-responsive">
      <Table striped bordered hover className="w-100">
            <thead>
              <tr>
                <th>Area Manager</th>
                <th>Total Unique Orders</th>
                <th>Total Unique Stores</th>
                <th>Sum of Cashback</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="4" className="text-center py-4">
                    <Spinner animation="border" role="status" variant="primary">
                    </Spinner>
                    <p className="mt-2 mb-0">Loading data...</p>
                  </td>
                </tr>
              ) : error ? (
                <tr>
                  <td colSpan="4" className="text-center text-danger py-3">
                    {error}
                  </td>
                </tr>
              ) : tableData.length > 0 ? (
                <>
                  {tableData.map((row, index) => (
                    <tr key={index}>
                      <td>{row.Area_Manager}</td>
                      <td>{row.Total_Unique_Orders}</td>
                      <td>{row.Total_Unique_Stores}</td>
                      <td>{row.Sum_of_Cashback}</td>
                    </tr>
                  ))}
                  <tr className="table-primary">
                    <td><strong>{calculateTotals().Area_Manager}</strong></td>
                    <td><strong>{calculateTotals().Total_Unique_Orders}</strong></td>
                    <td><strong>{calculateTotals().Total_Unique_Stores}</strong></td>
                    <td><strong>{calculateTotals().Sum_of_Cashback}</strong></td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td colSpan="4" className="text-center py-3">
                    No data available for the selected date range.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
      </div>

      <ToastContainer />
       
        </div>
        </Col>
        </Row>
        </Container>
        
        
    </>
  );
}

export default ScratchCardTransactionsReport;
 