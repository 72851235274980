import React, { useEffect, useState } from "react";
import { Button, Form, Card, ListGroup, Col,Spinner } from "react-bootstrap";
import { LoginContainer, LoginHeader, UserDetailsContainer } from "./wrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import vehicleRoutingIcon from "../../assests/images/gps.png";
import productSummaryIcon from "../../assests/images/product-summary.png";
import vehicleDetailsIcon from "../../assests/images/shipped.png";
import orderDetailsIcon from "../../assests/images/order.png";
import ordersLitingIcon from "../../assests/images/orderListing.png";
import loosePackingIcon from "../../assests/images/package.png";
import inventoryIcon from "../../assests/images/inventory.png";
import routePlanningIcon from "../../assests/images/delivery.png";
import paymentManagementIcon from "../../assests/images/payments.png";
import k1 from "../../assests/images/k1.png";

import NavBar from "../Navigation bar/Navbar";
import FooterComponent from "../Footer/footer";
import fire from "../../Configs/firebase";
import {
  validateIsOperationUser,
  validateIsBillingUser,
  validateIsAdminUser,
  validateIsPackingUser,
  validateIsFinanceUser,
} from "../UserCredentials";

let allowedRoutesSet = new Set();

export default function Home(props) {
  let userType = sessionStorage.getItem("originalUserType");
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (userType && userLoggedIn == false) {
      fire
        .database()
        .ref(`User_Role_Access/DeliveryPortal/${userType}/allowed_routes`)
        .once("value")
        .then((snapshot) => {
          let userRoleDetails = snapshot.val();
          console.log(userRoleDetails);
  
          if (userRoleDetails) {
            allowedRoutesSet = new Set();
            let userRoutes = Object.values(userRoleDetails);
            for (let i = 0; i < userRoutes.length; i++) {
              let route = `/${userRoutes[i]}`;
              if (!allowedRoutesSet.has(route)) {
                allowedRoutesSet.add(route);
              }
            }
            console.log(allowedRoutesSet);
            sessionStorage.setItem("allowedRoutes", JSON.stringify(userRoutes));
          } else {
            sessionStorage.setItem("allowedRoutes", JSON.stringify([]));
          }
          setUserLoggedIn(true);
        })
        .finally(() => {
          setIsLoading(false); 
        });
    }
  }, []);
  
  if (isLoading) {
    return (
      <>
        <NavBar />
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "100vh" }}>
          <Spinner 
            animation="border" 
            role="status" 
            variant="primary"
            style={{ width: "3rem", height: "3rem", marginBottom: "1rem" }}
          />
          <div className="text-center">
            <h4 className="text-primary mb-2">Loading...</h4>
            <p className="text-muted">Please wait until the page is loaded</p>
          </div>
        </div>
      </>
    );
  }

  const renderHeaderList = (title, variant, menuItems) => {
    const filteredItems = menuItems
      .filter(
        (item) =>
          allowedRoutesSet.has(item.path) ||
          validateIsAdminUser(JSON.parse(sessionStorage.getItem("user"))[0].email)
      )
      .map((item) => (
        <ListGroup.Item key={item.path} action href={item.path}>
          <i className="fa fa-bars" aria-hidden="true"></i> {item.label}
        </ListGroup.Item>
      ));
  
    return filteredItems.length > 0 ? (
      <ListGroup style={{ width: "200px", fontSize: "16px" }}>
        <ListGroup.Item variant={variant}>{title}</ListGroup.Item>
        {filteredItems}
      </ListGroup>
    ) : null;
  }

  return (
    <>
      <NavBar />
      <LoginContainer>
        <LoginHeader>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <span></span> {/* for logo, so don't remove the span */}
        </LoginHeader>

        <UserDetailsContainer>
          <Form style={{ margin: "10px" }}>
            <Form.Row>
              <Form.Group as={Col}>
                <ListGroup style={{ width: "200px", fontSize: "16px" }}>
                  <ListGroup.Item variant="danger">Inventory</ListGroup.Item>
                  {allowedRoutesSet.has("/inventory-movements") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) ? (
                    <ListGroup.Item action href="/inventory-movements">
                      <i class="fa fa-bars" aria-hidden="true"></i> Inventory
                      Details
                    </ListGroup.Item>
                  ) : (
                    ""
                  )}
                  {allowedRoutesSet.has("/transit-stock-transfer") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) ? (
                    <ListGroup.Item action href="/transit-stock-transfer">
                      <i class="fa fa-bars" aria-hidden="true"></i> Transit
                      Stock Transfer
                    </ListGroup.Item>
                  ) : (
                    ""
                  )}

                  {allowedRoutesSet.has("/transit-stock-return") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) ? (
                    <ListGroup.Item action href="/transit-stock-return">
                      <i class="fa fa-bars" aria-hidden="true"></i> Transit
                      Stock Return
                    </ListGroup.Item>
                  ) : (
                    ""
                  )}

                  {allowedRoutesSet.has("/stock-discrepancy") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) ? (
                    <ListGroup.Item action href="/stock-discrepancy">
                      <i class="fa fa-bars" aria-hidden="true"></i> Stock
                      Discrepancy
                    </ListGroup.Item>
                  ) : (
                    ""
                  )}
                  {allowedRoutesSet.has("/stock-correction") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) ? (
                    <ListGroup.Item action href="/stock-correction-report">
                      <i class="fa fa-bars" aria-hidden="true"></i> Stock
                      Correction
                    </ListGroup.Item>
                  ) : (
                    ""
                  )}
                  {allowedRoutesSet.has("/stock-correction") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) ? (
                    <ListGroup.Item action href="/bag-check-report">
                      <i class="fa fa-bars" aria-hidden="true"></i> Bag Check
                      Report
                    </ListGroup.Item>
                  ) : (
                    ""
                  )}
                    {allowedRoutesSet.has("/stockCheckingReport") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) ? (
                    <ListGroup.Item action href="/stockCheckingReport">
                      <i class="fa fa-bars" aria-hidden="true"></i> Delivery Stock Check
                      Report
                    </ListGroup.Item>
                  ) : (
                    ""
                  )}
                </ListGroup>
              </Form.Group>
              <Form.Group as={Col}>
                <ListGroup style={{ width: "200px", fontSize: "16px" }}>
                  <ListGroup.Item variant="primary">
                    <FontAwesomeIcon icon="fa-regular fa-truck" /> Pre Delivery
                  </ListGroup.Item>

                  {allowedRoutesSet.has("/loosepackorders") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) ? (
                    <ListGroup.Item action href="/loosepackorders">
                      <i class="fa fa-bars" aria-hidden="true"></i> Loose
                      Packing
                    </ListGroup.Item>
                  ) : (
                    ""
                  )}


            {allowedRoutesSet.has("/highValuePacks") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) ? (
                    <ListGroup.Item action href="/highValuePacks">
                      <i class="fa fa-bars" aria-hidden="true"></i> High
                      Value Packing
                    </ListGroup.Item>
                  ) : (
                    ""
                  )}    

                  {allowedRoutesSet.has("/productsummary") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) ? (
                    <ListGroup.Item action href="/productsummary">
                      <i class="fa fa-bars" aria-hidden="true"></i> Product
                      Summary
                    </ListGroup.Item>
                  ) : (
                    ""
                  )}

                  {allowedRoutesSet.has("/picking") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) ? (
                    <ListGroup.Item action href="/picking">
                      <i class="fa fa-bars" aria-hidden="true"></i> Picking
                    </ListGroup.Item>
                  ) : (
                    ""
                  )}
                  
                  <ListGroup.Item action href="/palletSticker">
                      <i class="fa fa-bars" aria-hidden="true"></i> Pallet Sticker Printing
                    </ListGroup.Item>
                  

                </ListGroup>
              </Form.Group>
              <Form.Group as={Col}>
                <ListGroup style={{ width: "200px", fontSize: "16px" }}>
                  <ListGroup.Item variant="secondary">
                    Delivery Details
                  </ListGroup.Item>

                  {allowedRoutesSet.has("/delivery-summary") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/delivery-summary">
                      <i className="fa fa-bars" aria-hidden="true"></i> Delivery
                      Summary
                    </ListGroup.Item>
                  ) : null}

                  {allowedRoutesSet.has("/driver-performance") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/driver-performance">
                      <i className="fa fa-bars" aria-hidden="true"></i> Driver
                      Performance
                    </ListGroup.Item>
                  ) : null}

                  {allowedRoutesSet.has("/transporter-payments") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/transporter-payments">
                      <i className="fa fa-bars" aria-hidden="true"></i>{" "}
                      Transporter Payments
                    </ListGroup.Item>
                  ) : null}

                  {allowedRoutesSet.has("/vehicle-details") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/vehicle-details">
                      <i className="fa fa-bars" aria-hidden="true"></i> Vehicle
                      Details
                    </ListGroup.Item>
                  ) : null}

                  {allowedRoutesSet.has("/routing") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/routing">
                      <i className="fa fa-bars" aria-hidden="true"></i> Vehicle
                      Routing
                    </ListGroup.Item>
                  ) : null}

                  {allowedRoutesSet.has("/Fresh") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/Fresh">
                      <i className="fa fa-bars" aria-hidden="true"></i> Fresh
                      Summary
                    </ListGroup.Item>
                  ) : null}

                  {allowedRoutesSet.has("/VariableCost") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/WeeklyVariableCost">
                      <i className="fa fa-bars" aria-hidden="true"></i> Weekly Variable Cost
                    </ListGroup.Item>
                  ) : null}
                </ListGroup>
              </Form.Group>
              <Form.Group as={Col}>
                <ListGroup style={{ width: "200px", fontSize: "16px" }}>
                  <ListGroup.Item variant="success">
                    Order Management
                  </ListGroup.Item>

                  {allowedRoutesSet.has("/orderlist") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/orderlist">
                      <i className="fa fa-bars" aria-hidden="true"></i> All
                      Orders (List)
                    </ListGroup.Item>
                  ) : null}

                  {allowedRoutesSet.has("/orders") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/orders">
                      <i className="fa fa-bars" aria-hidden="true"></i> Planned
                      Orders (Details)
                    </ListGroup.Item>
                  ) : null}
                  {allowedRoutesSet.has("/addreturnadjustmentorders") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/addreturnadjustmentorders">
                      <i className="fa fa-bars" aria-hidden="true"></i> Gate
                      Pass / Customer Returns Orders
                    </ListGroup.Item>
                  ) : null}
                  {allowedRoutesSet.has("/returnedorders") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/returnedorders">
                      <i className="fa fa-bars" aria-hidden="true"></i> Return
                      Orders
                    </ListGroup.Item>
                  ) : null}
                </ListGroup>
              </Form.Group>
              <Form.Group as={Col}>
                <ListGroup style={{ width: "200px", fontSize: "16px" }}>
                  <ListGroup.Item variant="warning">Payments</ListGroup.Item>
                  {allowedRoutesSet.has("/cheque-management") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/cheque-management">
                      <i className="fa fa-bars" aria-hidden="true"></i> Cheque
                      Management
                    </ListGroup.Item>
                  ) : null}
                  {allowedRoutesSet.has("/cash-management") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/cash-management">
                      <i className="fa fa-bars" aria-hidden="true"></i> Cash
                      Management
                    </ListGroup.Item>
                  ) : null}

                  {allowedRoutesSet.has("/payment-pending") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/payment-pending">
                      <i className="fa fa-bars" aria-hidden="true"></i> Payment
                      Pending
                    </ListGroup.Item>
                  ) : null}
                  {allowedRoutesSet.has("/super-kredit") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/super-kredit">
                      <i className="fa fa-bars" aria-hidden="true"></i> Super
                      Kredit
                    </ListGroup.Item>
                  ) : null}
                  {allowedRoutesSet.has("/scratch-card-transactions") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/scratch-card-transactions">
                      <i className="fa fa-bars" aria-hidden="true"></i> Scratch
                      Card
                    </ListGroup.Item>
                  ) : null}
                  {/* {allowedRoutesSet.has("/Rupifi") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/Rupifi">
                      <i className="fa fa-bars" aria-hidden="true"></i> Rupifi
                      Management
                    </ListGroup.Item>
                  ) : null} */}
                  {allowedRoutesSet.has("/Rupifi") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/Finaleap">
                      <i className="fa fa-bars" aria-hidden="true"></i> Finaleap
                      Management
                    </ListGroup.Item>
                  ) : null}

                {allowedRoutesSet.has("/Epay") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/Epay">
                      <i className="fa fa-bars" aria-hidden="true"></i> Epay
                      Management
                    </ListGroup.Item>
                  ) : null}


                </ListGroup>
              </Form.Group>

            </Form.Row>
          </Form>
        </UserDetailsContainer>
      </LoginContainer>
      <FooterComponent />
    </>
  );
}
