import React, { useState, useEffect } from 'react';
import { Modal, Button, Table,Spinner } from 'react-bootstrap';
import { NumberInput,Text } from "@mantine/core"; 
import { service_url, token } from "../../Configs/mysqlconfig";
import { toast } from "react-toastify";
import moment from 'moment';
const loggedUser = sessionStorage.getItem("user") || "";

const EditModal = ({ show, onHide, rowData, updateInventoryState, warehouse, productMasterDataRaw }) => {
  const [itemData, setItemData] = useState({});
  const [physicalInventory, setPhysicalInventory] = useState(rowData.physical_inventory || 0);
  const [physicalQtyInKg,setPhysicalQtyInKG] = useState('');
  const [stockLossQty, setStockLossQty] = useState("");
  const [stockLossQtyKg, setStockLossQtyKg] = useState("");
  const [stockLossValue, setStockLossValue] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
   const [isStockLossValid, setIsStockLossValid] = useState(false);
    const [stockLossError, setStockLossError] = useState('');


  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const fetchData = () => {
    
    let newDate = moment(new Date()).format("YYYY-MM-DD")
    const landingPriceUrl = `${service_url}/api/superzop/pricelist/price-details?token=${token}`;
    const pickingURL = `${service_url}/inventory-picking?inventory_date=${newDate}&item_id=${rowData.item_id}&city=${warehouse}`;
  
    Promise.all([
      fetch(landingPriceUrl, {
        headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
      }).then((res) => res.json()),
  
      fetch(pickingURL, {
        headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
      }).then((res) => res.json())
    ])
    .then(([landingPriceResult, pickingResult]) => {
      const landingPriceData = landingPriceResult.data || {};
      const { data } = pickingResult;
      const uom = productMasterDataRaw[rowData.item_id]?.uom || '';
      const landing_cost = landingPriceData[rowData.item_id]?.landing_cost || '';
      const updatedItem = {
        ...rowData,
        inventory_after_shipped: Number(data).toFixed(2),
        system_inventory_kg: (Number(data) * Number(rowData.outer_size)).toFixed(2),
        inventory: Number(data).toFixed(2),
        uom: uom,
        landing_cost: landing_cost,
      };
      
      setItemData(updatedItem);
      setLoading(false); 
    })
    .catch((err) => {
      toast(`Error in fetching data: ${err}`, {
        type: toast.TYPE.ERROR,
      });
      setLoading(false); 
    });
  };

  // get valid no. for mantine no. input as it accepts only no. values
  const getValidNumber = (num) => isNaN(Number(num)) || Number(num) === 0 ? "" : Number(num);

  const calculateFromPhysicalInventory = (physicalInventory, itemData) => {
    const lossQty = getValidNumber(calculateStockLossQty(physicalInventory, itemData, itemData.inventory_after_shipped));
    const lossValue = calculateStockValue(lossQty, itemData.landing_cost, itemData);
    const lossQtyKg = getValidNumber(calculateStockLossInKg(lossQty, itemData));
    setStockLossQtyKg(lossQtyKg);
    setStockLossQty(lossQty);
    setStockLossValue(lossValue);
  };

  const calculateFromStockLossQty = (lossQty, itemData) => {
    const lossValue = calculateStockValue(lossQty, itemData.landing_cost, itemData);
    const lossQtyKg = getValidNumber(calculateStockLossInKg(lossQty, itemData));
    const physicalInventoryCalc = getValidNumber(calculatePhysicalFromLossGainQty(lossQty));
    
    
    setPhysicalQtyInKG(calculatePhysicalInKg(physicalInventoryCalc))
    setPhysicalInventory(physicalInventoryCalc);
    setStockLossQtyKg(lossQtyKg);
    setStockLossValue(lossValue);
  };

//   const handlePhysicalInventoryChange = (value) => {
//     const newPhysicalInventory = value || 0;
//     setPhysicalInventory(newPhysicalInventory);
//     calculateStockValues(newPhysicalInventory, itemData);
//   };

const validatePhysicalInventory = (value) => {
    if (value === '') {
      return 'Please enter data for physical inventory';
    } else if (parseFloat(value) === parseFloat(itemData.inventory_after_shipped)) {
      return 'Physical inventory can\'t be equal to system inventory';
    } else if (parseFloat(value) < 0) {
      return 'Physical inventory can\'t be negative';
    }
    // else if(value == 0){
    //     return 'Physical inventory can\'t be zero';
    // }
    return '';
  };

  const handlePhysicalInventoryChange = (value) => {
    if(value == undefined || ""){
      resetValueOnEmpty();
      return;
    }
    const errorMsg = validatePhysicalInventory(value);
    setError(errorMsg);
    if (errorMsg === '') {
      setPhysicalInventory(value);
      calculateFromPhysicalInventory(value, itemData);
      setPhysicalQtyInKG(calculatePhysicalInKg(value))
    } else {
    //   setPhysicalInventory(value);
      setStockLossQty('');
      setStockLossQtyKg('');
      setStockLossValue('');
    }
  };

  function calculatePhysicalInKg(inputPhysicalvalue){
    const actualChildWeight = parseFloat(getActualChildWeight(itemData.outer_size, itemData.weight, itemData.uom));
    if (inputPhysicalvalue === undefined || inputPhysicalvalue === null) {
      return ""; 
    }
    return (actualChildWeight * inputPhysicalvalue).toFixed(4);
  }
  const validateStockLossGain = (value) => {
    if (value === '' || value === 0) {
      return 'Stock loss/gain quantity cannot be zero';
    }
    if (value === '-') {
      return 'Stock loss/gain quantity cannot be undefined';
    }
    return '';
  };

  const handleStockLossGainQtyChange = (value) => {
    if(value == undefined || ""){
      resetValueOnEmpty();
      return;
    }
   

    const errorMsg = validateStockLossGain(value);
    setStockLossError(errorMsg);
    setIsStockLossValid(errorMsg === '');
    
    if (errorMsg === '') {
      setStockLossQty(value);
      calculateFromStockLossQty(value, itemData);
    }
   
  };

  const calculatePhysicalFromLossGainQty = (lossGainQty) => {
    return (parseFloat(lossGainQty || 0) + parseFloat(rowData.inventory_after_shipped|| 0)).toFixed(4);
  };

  // function calculateStockLossQty(physicalInventory, inventoryProduct, inventoryOfProduct) {
  //   const actualChildWeight = parseFloat(getActualChildWeight(inventoryProduct.outer_size, inventoryProduct.weight, inventoryProduct.uom));
  //   return (((physicalInventory || 0) / actualChildWeight) - inventoryOfProduct).toFixed(2);
  // }

  function calculateStockLossQty(physicalInventory, inventoryProduct, inventoryOfProduct) {
    const stockLossQty = parseFloat(physicalInventory || 0) - parseFloat(inventoryOfProduct || 0);
    return stockLossQty.toFixed(4);
  }

  function calculateStockValue(stockLossQty, productPrice, inventoryProduct) {
    const weight = parseFloat(getNumbersFromString(inventoryProduct.weight));
    return (stockLossQty * (productPrice * weight)).toFixed(4);
  }

  function calculateStockLossInKg(stockLossQty, inventoryProduct) {
    const actualChildWeight = parseFloat(getActualChildWeight(inventoryProduct.outer_size, inventoryProduct.weight, inventoryProduct.uom));
    return (stockLossQty * actualChildWeight).toFixed(4);
  }

  function getNumbersFromString(input) {
    return input.replace(/[^0-9]/g, "");
  }

  function getActualChildWeight(outerSize, weight, uom) {
    if (uom.toLowerCase() === "pc" && weight.toLowerCase().includes("gm")) {
      return (parseFloat(weight.replace(/[^0-9]/g, "")) / 1000).toString();
    } else if (uom.toLowerCase() === "pc" && weight.toLowerCase().includes("kg")) {
      return weight.replace(/[^0-9]/g, "");
    } else {
      return outerSize;
    }
  }

  useEffect(() => {
    if (show && rowData) {
      fetchData();
    }
  }, [show, rowData]);

  const handleSave = () => {
    if (physicalInventory < 0) {
      setError("Inventory after stock correction cannot be negative!");
      return;
    } if(error){
        return toast(`${error}`, { type: toast.TYPE.ERROR });
    } 
    if (Number(stockLossQty) == 0 && Number(stockLossValue) == 0) {
        return toast("No change in inventory cannot edit a correction!",{ type: toast.TYPE.ERROR });
        
      }
      if(stockLossQty == '' || stockLossValue == 'NaN'){
        return toast("No change in inventory cannot edit a correction! please provide a valid value for stock loss-gain qty",{ type: toast.TYPE.ERROR });
      }
    UpdateStockCorrection()
    // updateInventoryState("physical_inventory", physicalInventory);
    onHide();
  };

  const UpdateStockCorrection = () => {
    let itemToSave = {
      warehouse: warehouse,
      item_id: itemData.item_id,
      quantity: stockLossQty,
      inventory_after_shipped: itemData.inventory_after_shipped,
      inventory: itemData.inventory_after_shipped,
      id: itemData.id,
      created_by : JSON.parse(loggedUser)[0].email,
      updated_at: moment().format("YYYY-MM-DD HH:mm:ss")
    };

    try {
      if (physicalInventory === "") {
        return toast(`Physical inventory doesn't contain any value`, { type: toast.TYPE.ERROR });
      }

      let updateURL = `${service_url}/api/update-stock-correction?token=${token}`;
      fetch(updateURL, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(itemToSave),
      })
      .then(response => response.json())
      .then(data => {
        toast(`${data.message}`, { type: toast.TYPE.SUCCESS });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(error => {
        return toast("Couldn't update: " + error, { type: toast.TYPE.ERROR });
      });
    } catch (err) {
      return toast(`${err.message}`, { type: toast.TYPE.ERROR });
    }
  };

  const resetValues = () => {
    setItemData({});
    setPhysicalInventory(rowData.physical_inventory || 0);
    setStockLossQty("");
    setStockLossQtyKg("");
    setStockLossValue("");
    setError("");
    setLoading(true);
    setPhysicalQtyInKG('');
  };

  const resetValueOnEmpty = () => {
    setStockLossQty(undefined);  // mantine support undefined for empty 
    setStockLossQtyKg("");
    setStockLossValue("");
    setPhysicalInventory(undefined);
    setPhysicalQtyInKG('');
  }

  const handleClose = () => {
    resetValues();
    onHide();
  };

  const stockLossGainStyle = (value) => ({
    color: Number(value) > 0 ? 'green' : 'red',
  });

  return (
    <Modal show={show} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Stock Correction</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {loading ? (
                    <div className="d-flex justify-content-center align-items-center">
                        <Spinner animation="border" />
                    </div>
                ):(
                    <>
        {error && <div className="alert alert-danger">{error}</div>}
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Item ID</th>
              <th>Description</th>
              <th>System Inventory In QTY(KG)</th>
              <th>Physical Inventory In QTY</th>
              <th>Stock Loss/Gain In QTY(KG)</th>
              <th>Stock Loss/Gain (Value)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{itemData.item_id}</td>
              <td>{itemData.item_disc}</td>
              <td>{itemData.inventory_after_shipped} {itemData.uom !== 'PC' && `(${itemData.system_inventory_kg} KG)` }</td>
              <td>
                <NumberInput
                  value={physicalInventory}
                  precision={4}
                  onChange={handlePhysicalInventoryChange}
                />
                {itemData.uom !== 'PC' && physicalQtyInKg ? `${physicalQtyInKg} KG` : ""}
              </td>
              <td style={stockLossGainStyle(stockLossQty)}>
              <NumberInput
                  value={stockLossQty}
                  precision={4}
                  onChange={handleStockLossGainQtyChange}
                />
                  {itemData.uom !== 'PC' && stockLossQtyKg ?`${stockLossQtyKg} KG`:""}
               {stockLossError && <Text color="red" size="sm">{stockLossError}</Text>}
              </td>
              <td style={stockLossGainStyle(stockLossValue)}>
              {error === '' && physicalInventory > 0 ? stockLossValue : ''}
              </td>
              
            </tr>
          </tbody>
        </Table>
        </>)}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Close</Button>
        <Button variant="primary" onClick={handleSave}>Save Changes</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
