import React, { useState, useMemo, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import fire from "../../Configs/firebase";
import { orderingApiUrl } from "../../Configs/mysqlconfig";
import moment from "moment";

import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Spinner,
  Table,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
import NavBar from "../Navigation bar/Navbar";

let today = new Date();
let dd = String(today.getDate()).padStart(2, "0");
let mm = String(today.getMonth() + 1).padStart(2, "0");
let yyyy = today.getFullYear();
var today_date = yyyy + "-" + mm + "-" + dd;

function ScratchCardTransactions() {
  const [B4Init, setB4Init] = useState(true);
  const [show1, setShow1] = useState(false);
  const [UpdateIds, setUpdateIds] = useState([]);
  const [ScratchCardData, setScratchCardData] = useState([]);
  const [FireBaseData, setFireBaseData] = useState({});
  const [TableDate, setTableDate] = useState(today_date);
  const [NotAvailed, setNotAvailed] = useState([]);
  const [Availed, setAvailed] = useState([]);
  const [filteredAvailed, setFilteredAvailed] = useState([]);

  const [Loading, setLoading] = useState(false);
  const [CbLoading, setCbLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [Reload, setReload] = useState(false);
  const [IsAllSet, setIsAllSet] = useState();

  const [show2, setShow2] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment().subtract(1, "days").format("YYYY-MM-DD"));

  // const filteredAvailed = Availed?.filter(
  //   (item) => moment(item?.created_at).format("YYYY-MM-DD") === selectedDate
  // );

  const handleClose1 = () => setShow1(false);
  const handleShow1 = async () => {
    try {
      const response = await axios.get(`${orderingApiUrl}/orderListDetails?token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik`);
      
      if (response.status === 200) {
        let strachCardData = response.data.data;
        let Not_Availed = [];
        let allYes = "true";
        console.log("strachCardData : " + strachCardData);
        strachCardData.map((item) => {

          let [a, b] = item?.order_number.split("-");
          let formatted_oder_id = `${a}/${b}`;

            allYes = "false";
            Not_Availed.push({
              order_number: item?.order_number,
              retailer_id: item?.retailer_id,
              cashback_value: item?.cashback_value,
              created_at: item?.delivery_date,
              is_processed: item?.is_processed,
            });
        });
        Not_Availed.sort((a, b) => {
          return new Date(a.created_at) - new Date(b.created_at);
        });
      
        setNotAvailed(Not_Availed);
        setShow1(true);
      } else {
        console.error("API Call Failed", response);
        toast.error("Error fetching processed Data");
      }
    } catch (error) {
      console.error("Error calling API:", error);
      toast.error("Error fetching processed Data");
    }
  };

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => {
    setShow2(true);
    setSelectedDate(moment().subtract(1, "days").format("YYYY-MM-DD"));
  };

  useEffect(() => {
    apiDataCall();
    // scratchCardTransactions();
    return () => {};
  }, [TableDate, Reload]);

  useEffect(() => {
    const fetchData = async () => {
      setCbLoading(true);

      await scratchCardTransactions();

      setCbLoading(false);
    };

    if (selectedDate) {
      fetchData();
    }
  }, [selectedDate]);

  useEffect(() => {
    setFilteredAvailed(
      Availed.filter((item) => {
        // moment(item.delivery_date, "D-M-YYYY").format("YYYY-MM-DD") === selectedDate
        if (!item?.delivery_date) return false;
        let formattedDate = moment(item.delivery_date, "D-M-YYYY").format("YYYY-MM-DD");
        return formattedDate === selectedDate;
      })
    );
  }, [Availed, selectedDate]);

  async function apiDataCall() {
    setLoading(true);
    try {
      let result = await axios.get(
        `${orderingApiUrl}/scratch-card-transactions?token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik&delivery_date=${TableDate}&is_processed=N`
      );

      // let resultOrdersList = await axios.get (`${orderingApiUrl}/orderListDetails?token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik`);

      // let strachCardData = resultOrdersList.data.data;
      let selectedDate = new Date(TableDate);
      let year = selectedDate.getFullYear();
      let month = selectedDate.getMonth() + 1;
      let date = selectedDate.getDate() + "-" + month + "-" + year;

      let doh = await fire
        .database()
        .ref(`Driver_Orders_History/${year}/${month}/${date}/`);
      let snapshot = await doh.once("value");
      let val = snapshot.val();

      let fb_data = {};

      for (let root in val) {
        for (let odr in val[root]) {
          if (
            fb_data[val[root][odr].order_number] === undefined &&
            val[root][odr]?.status.toLowerCase().search("delivered") !== -1
          ) {
            fb_data[val[root][odr]?.order_number] = {
              root: root,
              total_amount_paid:
                val[root][odr]?.payment_details?.total_amount_paid,
              status: val[root][odr]?.status,
              city: val[root][odr]?.city,
              delivery_date:date
            };
          }
        }
      }

      // let Not_Availed = [];
      let allYes = "true";
      let list_of_id = [];
      // console.log("strachCardData : " + strachCardData);
      // strachCardData.map((item) => {

      //   let [a, b] = item?.order_number.split("-");
      //   let formatted_oder_id = `${a}/${b}`;

      //   //if (item?.is_processed == "N" && item?.is_scratched == "Y" ) {
      //     allYes = "false";
      //     Not_Availed.push({
      //       order_number: item?.order_number,
      //       retailer_id: item?.retailer_id,
      //       cashback_value: item?.cashback_value,
      //       created_at: item?.delivery_date,
      //       is_processed: item?.is_processed,
      //     });
      //   //}

      //   return;

      // });

      result.data.data.map((item) => {

          let [a, b] = item?.order_number.split("-");
          let formatted_oder_id = `${a}/${b}`;

          if (item?.is_scratched == "Y" && item?.is_processed == 'N') {
            allYes = "false";
            // Not_Availed.push({
            //   order_number: item?.order_number,
            //   retailer_id: item?.retailer_id,
            //   cashback_value: item?.cashback_value,
            //   created_at: item?.delivery_date,
            //   is_processed: item?.is_processed,
            // });

            if (fb_data[formatted_oder_id] === undefined) {
              return;
            }
            let idList = {
              id : item?.id,
              order_number : item?.order_number,
              retailer_id: item?.retailer_id,
              cashback_value: item?.cashback_value,
              phone_num: item?.retailer_phone
            }
            list_of_id.push(idList);
          }

          return;

      });

      // Not_Availed.sort((a, b) => {
      //   return new Date(a.created_at) - new Date(b.created_at);
      // });
      setFireBaseData(fb_data);
      setScratchCardData(result.data.data);
      // setNotAvailed(Not_Availed);
      setIsAllSet(allYes);
      setUpdateIds(list_of_id);

      setLoading(false);
      setB4Init(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function scratchCardTransactions() {
    setCbLoading(true);
    try {

      let result = await axios.get(`${orderingApiUrl}/scratch-card-transactions?token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik&is_processed=Y`);
      let selectedDateInner = new Date(selectedDate);
      let year = selectedDateInner.getFullYear();
      let month = selectedDateInner.getMonth() + 1;
      let date = selectedDateInner.getDate() + "-" + month + "-" + year;

      let doh = await fire
        .database()
        .ref(`Driver_Orders_History/${year}/${month}/${date}/`);
      let snapshot = await doh.once("value");
      let val = snapshot.val();

      let fb_data = {};

      for (let root in val) {
        for (let odr in val[root]) {
          if (
            fb_data[val[root][odr].order_number] === undefined &&
            val[root][odr]?.status.toLowerCase().search("delivered") !== -1
          ) {
            fb_data[val[root][odr]?.order_number] = {
             delivery_date:date
            };
          }
        }
      }
      let updatedData = result.data.data
                      .map((item) => {
                        let formattedOrderNumber = item.order_number.replace(/-/g, "/");
                         return {
                            ...item,
                            delivery_date: fb_data[formattedOrderNumber]?.delivery_date || null,
                          };
                      })
      // updatedData = updatedData.filter((item) => {
      //   if (!item?.delivery_date) return false;
      //   let formattedDate = moment(item.delivery_date, "D-M-YYYY").format("YYYY-MM-DD");
      //   return formattedDate === selectedDate;
      // });
      setCbLoading(false);
      setAvailed(updatedData);

    } catch (error) {
      console.log(error);
    }


  }

  async function bulkUpdate() {
    try {
      if (isUpdating) return; 
      setIsUpdating(true);

      await axios.put(
        `${orderingApiUrl}/avail-scratch-card-transactions?token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik`,
        { idList: UpdateIds, delivery_date: TableDate }
      );
      toast.success("Update successfully");
      setReload(!Reload);
    } catch (error) {
      setIsUpdating(false);
      console.log(error);
      toast.error("Update Not successfully");
    }
  }

  function Table1() {
    const [tableData, setTableData] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
    if (ScratchCardData.length == 0 || FireBaseData.length == 0) {
      return;
    }

    let tempData = [];

    ScratchCardData.forEach((item) => {
      let [a, b] = item?.order_number.split("-");
      let formatted_order_id = `${a}/${b}`;
      let formattedDate = moment(FireBaseData[formatted_order_id]?.delivery_date, "D-M-YYYY").format("YYYY-MM-DD");

      if (FireBaseData[formatted_order_id] && formattedDate === TableDate && item.is_scratched == 'Y') {
        tempData.push({
          id: item?.id,
          retailer_id: item?.retailer_id,
          order_number: formatted_order_id,
          processed_date: item?.processed_date,
          cashback_value: item?.cashback_value,
          created_at: item?.created_at?.slice(0, 10),
          order_amt: item?.order_amt,
          status: item?.status,
          scratched_date: item?.scratched_date,
          is_processed: item?.is_processed,
          is_scratched: item?.is_scratched,
          root: FireBaseData[formatted_order_id]?.root,
          total_amount_paid: FireBaseData[formatted_order_id]?.total_amount_paid,
          city: FireBaseData[formatted_order_id]?.city,
          x: IsAllSet,
          fb_status: FireBaseData[formatted_order_id]?.status,
        });
      }
    });

    setTableData(tempData);
    setIsDataLoaded(true); // Mark data as loaded
    }, [ScratchCardData, FireBaseData, TableDate]);

    useEffect(() => {
      if (isDataLoaded && ScratchCardData.length !== 0 && tableData.length === 0 && Loading === false && B4Init === false) {
        toast.error("No data found for this date", { autoClose: 1000 });
        setB4Init(true);
      }
    }, [isDataLoaded, ScratchCardData, tableData, Loading, B4Init]);

    const data = useMemo(() => tableData, [tableData]);
    const columns = useMemo(
      () => [
        {
          Header: "Warehouse",
          accessor: "city",
          Filter: SelectColumnFilter,
        },
        {
          Header: "Route",
          accessor: "root",
        },
        {
          Header: "Retailer id",
          accessor: "retailer_id",
        },
        {
          Header: "Created At",
          accessor: "created_at",
        },

        {
          Header: "Order number",
          accessor: "order_number",
        },

        {
          Header: "Status",
          accessor: "fb_status",
          Filter: SelectColumnFilter,
        },
        {
          Header: "Cashback value",
          accessor: "cashback_value",
        },

        {
          Header: "Total amount paid",
          accessor: "total_amount_paid",
        },
        // {
        //   Header: "X",
        //   accessor: "is_processed"
        // },
        {
          Header: "Is Processed",
          accessor: "x",
          Cell: ({ row }) => {
            if (row.original.is_processed === "Y") {
              return "✔️";
            } else {
              return "❌";
            }
          },
        },
      ],
      []
    );

    function SelectColumnFilter({
      column: { filterValue, setFilter, preFilteredRows, id },
    }) {
      const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
          options.add(row.values[id]);
        });
        return [...options.values()];
      }, [id, preFilteredRows]);

      return (
        <select
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">All</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }

    const defaultColumn = useMemo(
      () => ({
        Filter: () => null,
      }),
      []
    );

    const sortees = React.useMemo(
      () => [
        {
          id: "city",
          desc: false,
        },
        {
          id: "retailer_id",
          desc: false,
        },
        {
          id: "root",
          desc: false,
        },
      ],
      []
    );
    const GlobalFilter = ({ filter, setFilter }) => {
      const [value, setValue] = useState(filter);
      const onChange = useAsyncDebounce((value) => {
        setFilter(value || undefined);
      }, 1000);
      return (
        <span>
          <input
            placeholder="Search..."
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
        </span>
      );
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      setGlobalFilter,
      preGlobalFilteredRows,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {
          sortBy: sortees,
        },
      },
      useFilters,
      useGlobalFilter,
      useSortBy
    );

    const { globalFilter } = state;

    return (
      <div>
        <Row>
          <Col>
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
            ></GlobalFilter>
          </Col>
          <Col>
            {IsAllSet !== "true" ? (
              <Button
                variant="primary"
                onClick={bulkUpdate}
                disabled={UpdateIds.length === 0 || isUpdating}
              >
                Bulk Update
              </Button>
            ) : (
              <Button variant="secondary" disabled>
                Bulk Update
              </Button>
            )}
          </Col>
          <Col>
            <Button variant="primary" onClick={handleShow1}>
              Not Processed
            </Button>
          </Col>
          <Col>
            <Button variant="primary" onClick={handleShow2}>
              Cashbacks
            </Button>
          </Col>
          <Col>
            <Button variant="primary" onClick={() => window.open("/scratch-transactions-report", "_blank")}>
              Report
            </Button>
          </Col>

          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
        </Row>
        <br />

        <table
          {...getTableProps()}
          style={{ border: "solid 1px blue", width: "100%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "solid 3px red",
                      background: "aliceblue",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {column.render("Header")}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          border: "solid 1px gray",
                          background: "papayawhip",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div>
      <NavBar />
      <br />
      <br />
      <br />
      <br />

      <div style={{ margin: 20 }}>
        <h2>
          Scratch Card Transactions{" "}
          {Loading && (
            <Spinner animation="border" role="status">
              {" "}
            </Spinner>
          )}{" "}
        </h2>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="date"
                value={TableDate}
                onChange={(e) => setTableDate(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
        </Row>

        {/* {JSON.stringify(FireBaseData)} */}
        {/* {JSON.stringify(ScratchCardData.length)} */}
        {/* {JSON.stringify(table_data.length)} */}
        {/* {JSON.stringify(Loading)} */}

        <Row>
          <Col>
            <Table1 />
          </Col>
        </Row>

        <Modal show={show1} onHide={handleClose1} size="lg">
          <Modal.Header closeButton>
            <Modal.Title> Scratch Card not processed </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Retailer id</th>
                  <th>Order number</th>
                  <th>Cashback value</th>
                  <th>Delivery Date</th>
                  <th>Is Processed</th>
                </tr>
              </thead>
              <tbody>
                {NotAvailed &&
                  NotAvailed.map((item) => {
                    return (
                      <tr>
                        <td>{item?.retailer_id}</td>
                        <td>{item?.order_number}</td>
                        <td>{item?.cashback_value}</td>
                        <td>{item?.created_at}</td>
                        <td>{item?.is_processed}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show2} onHide={handleClose2} size="lg">
          <Modal.Header closeButton>
            <Modal.Title> Processed Cashback </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="deliveryDateFilter">
              <Form.Label> Delivery Date:</Form.Label>
              <Form.Control
                className="mb-3"
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </Form.Group>

            {/* Show Spinner While Loading */}
            {CbLoading ? (
              <div className="text-center">
                <Spinner animation="border" role="status" />
              </div>
            ) : (
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>id</th>
                    <th>retailer_id</th>
                    <th>order_number</th>
                    <th>processed_date</th>
                    <th>cashback_value</th>
                    <th>delivery_date</th>
                    <th>order_amt</th>
                    <th>status</th>
                    <th>scratched_date</th>
                    <th>is_processed</th>
                    <th>is_scratched</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAvailed.length > 0 ? (
                    filteredAvailed.map((item) => (
                      <tr key={item?.id}>
                        <td>{item?.id}</td>
                        <td>{item?.retailer_id}</td>
                        <td>{item?.order_number}</td>
                        <td>{item?.processed_date}</td>
                        <td>{item?.cashback_value}</td>
                        <td>{item?.delivery_date}</td>
                        <td>{item?.order_amt}</td>
                        <td>{item?.status}</td>
                        <td>{item?.scratched_date}</td>
                        <td>{item?.is_processed}</td>
                        <td>{item?.is_scratched}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="11" className="text-center">
                        No data available for selected date
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );

}

export default ScratchCardTransactions;
