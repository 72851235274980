import React, {
  useState,
  useEffect,
  useRef,
  memo,
  useCallback,
  forwardRef,
} from "react";
import {
  Table,
  Form,
  Alert,
  Button,
  Modal as BootStrapModal,
  Row,
  Col,
  Num
} from "react-bootstrap";
import styled from "styled-components";

import { service_url, token } from "../../Configs/mysqlconfig";
import moment from "moment";
import { toast } from "react-toastify";
import { Select } from "react-select";
import {
  Select as MantineSelect,
  Modal,
  Autocomplete,
  Textarea,
  Avatar,
  Group,
  Text,
  NumberInput,
  Checkbox,
} from "@mantine/core";
import Backdrop from "../backdrop/backdrop";
import fire from '../../Configs/firebase';
import AddNewProductItem from "./AddNewProductItem";
import { warehouseNames } from "../../Configs/mysqlconfig";
import { warehouses } from '../../indexedDBUtility.js';

const GLOBAL_PARENT_WAREHOUSE = "Bhiwandi";
const userCity = sessionStorage.getItem("userCity") || "";
const userType = sessionStorage.getItem("userType") || "";
const loggedUser = sessionStorage.getItem("user") || "";

const mapWarehouseList = (names) => {
  return names.map(name => ({
    value: name,
    label: name
  }));
};

// const targetWarehouseList = mapWarehouseList(warehouseNames);

// const targetWarehouseList = [ { value: "Bhiwandi", label: "Bhiwandi" }];

const WarehouseSelectContainer = styled.div`
  width: 15rem;
  margin: 0 1rem;
  margin-bottom: 1.5rem;
  align-self: flex-end;
`;

const CenterText = styled.div`
  text-align: center;
`;

function StockCorrectionCreationModal({ warehouse,unApprovedCorrections, ...props }) {
  const [selectedItemId, setSelectedItemId] = useState("");
  const [targetWarehouse, setTargetWarehouse] = useState(warehouse);

  const[targetWarehouseList,setTargetWarehouseList] = useState([{ value: "All", label: "All" }])

  useEffect(() => {
    warehouses
      .then(data => {
        console.log("Fetched Warehouses:", data);
        const allWarehouses = [
          { value: "All", label: "All" },
          ...data.map(obj => ({ value: obj.warehouse, label: obj.warehouse }))
        ];
        setTargetWarehouseList(allWarehouses);
        console.log("All warehouses:", allWarehouses);
      })
      .catch(error => {
        console.error("Failed to fetch warehouses:", error);
      });
  }, []);


  const [backDrop, setBackDrop] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [inventoryMapRaw, setInventoryMapRaw] = useState([]);
  const [inventoryMap, setInventoryMap] = useState([]);
  const [priceDetails, setPriceDetails] = useState({});
  const [productTypes,setProductTypes] = useState([]);
  const [productObjects,setProductObjects] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState("all");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const[error,setError] = useState("")
  const defaultStockStatus = "";

  const addTransitItemDropdownList = props.addTransitItemDropdownList;
  const stockDate = props.stockDate;
  const productMasterDataRaw = props.productMasterDataRaw;
  const isModalOpen = props.show;
  const setIsModalOpen = props.onHide;

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });
  
  const fetchData = () => {
    setBackDrop(true);
  //  landing price api
    let landingPriceUrl = `${service_url}/api/superzop/pricelist/price-details?token=${token}`;
  
    Promise.all([
      fetch(landingPriceUrl, {
        headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
      }).then((res) => res.json())
    ])
    .then(([landingPriceResult]) => {
      let landingPriceData = landingPriceResult.data || {};
      let mergedData = mergeData(productMasterDataRaw, landingPriceData);
       // for product type selection
      const uniqueProductTypes = new Set();
      const productObjectsArray = [];
  
      for (let key in productMasterDataRaw) {
        if (productMasterDataRaw.hasOwnProperty(key)) {
          const product = productMasterDataRaw[key];
  
          // Check if product_type exists, is non-empty, and non-numeric
          if (product.product_type && isNaN(product.product_type) && product.product_type.trim() !== "") {
            uniqueProductTypes.add(product.product_type);
          }
          productObjectsArray.push(product);
        }
      }
      const uniqueProductTypesArray = Array.from(uniqueProductTypes).map(type => ({ value: type, label: type })).concat([{ value: 'all', label: 'All' }]);
  
      setProductTypes(uniqueProductTypesArray)
      setProductObjects(productObjectsArray)
      // can set selected producttype state here to check glicth in binding of ui at first time sometimes
      setPriceDetails(landingPriceData);
      setInventoryMapRaw(mergedData);
      // setInventoryMap(mergedData);
      setBackDrop(false);
    })
    .catch((err) => {
      setBackDrop(false);
      toast(`Error in fetching data: ${err}`, {
        type: toast.TYPE.ERROR,
      });
    });
  };

  const mergeData = (pdtmastData, landingPriceData) => {
      let products  = Object.values(pdtmastData);

      let mergedData = products.map((product) => {
      let matchedObj = landingPriceData[product.item_id];
      if (matchedObj !== undefined) {
        return { ...product, landing_cost: matchedObj.landing_cost };
      } else {
        return product;
      }
    });
    return mergedData;
  };
 
  useEffect(() => {
    fetchData();
  }, [targetWarehouse]);


  useEffect(() =>{
    if(selectedItem){
    let newDate = moment(new Date()).format("YYYY-MM-DD")
    let pickingURL = `${service_url}/inventory-picking?inventory_date=${newDate}&item_id=${selectedItem.item_id}&city=${targetWarehouse}`;
    // let pickingURL = `https://dev-services2.superzop.com/inventory-picking?inventory_date=2024-7-30&item_id=${selectedItem.item_id}`;
    Promise.all([
      fetch(pickingURL).then((res) => res.json())
    ]).then(([pickingResult]) => {
      const { data } = pickingResult;
      const updatedItem = {
        ...selectedItem,
        inventory_after_shipped: Number(data).toFixed(2),
        system_inventory_kg: Number(data).toFixed(2)*Number(selectedItem.outer_size),
        inventory: Number(data).toFixed(2),
        remarks: "",
        isEditable: false,
        stock_status: defaultStockStatus,
      };

      const selectedTypeLower = selectedProductType.toLowerCase();
      const itemTypeLower = selectedItem.product_type.toLowerCase();
      if (selectedTypeLower == 'all' || itemTypeLower ==  selectedTypeLower) {
        setInventoryMap([updatedItem]);
      }else{
        setInventoryMap([]);
      }

    })}else{
      setInventoryMap([]);
    }
  },[selectedItem,selectedProductType,targetWarehouse])

  useEffect(() => {
    // Filter options based on the search term
    const options = inventoryMapRaw
      .filter(item => 
        item.item_id.toLowerCase().includes(searchTerm.toLowerCase()) || 
        (item.long_description && item.long_description.toLowerCase().includes(searchTerm.toLowerCase()))
      )
      .map(item => `${item.item_id} - ${item.long_description}`);
      // one more condtion for selected prodcut type
    setFilteredOptions(options);
  }, [searchTerm,selectedProductType]);

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const handleOptionSelect = (value) => {
    const selectedItem = inventoryMapRaw.find(item => `${item.item_id} - ${item.long_description}` == value);
    setSelectedItem(selectedItem);
  };

  const updateInventoryState = useCallback((key, value, index) => {
    setInventoryMap((prevState) => {
      let _inventoryMap = [...prevState];
      // console.log(value)
      _inventoryMap[index][key] = value;
      return _inventoryMap;
    });

    if (key === "isEditable" && value === false) {
      resetRowFields(index);
    }
  }, []);

  const resetRowFields = useCallback((index) => {
    setInventoryMap((prevState) => {
      let _inventoryMap = [...prevState];
      _inventoryMap[index].stock_status = defaultStockStatus;
      _inventoryMap[index].physical_inventory = 0;
      _inventoryMap[index].remarks = "";

      return _inventoryMap;
    });
  }, []);

  function getActualChildWeight(outerSize, weight, uom) {
    if (uom.toLowerCase() == "pc" && weight.toLowerCase().includes("gm")) {
        return (parseFloat(weight.replace(/[^0-9]/g, "")) / 1000).toString();
    } else if (uom.toLowerCase() == "pc" && weight.toLowerCase().includes("kg")) {
        return weight.replace(/[^0-9]/g, "");
    } else {
        return outerSize;
    }
}

function getNumbersFromString(input) {
    return input.replace(/[^0-9]/g, "");
}

function calculateStockLossQty(physicalInventory, inventoryProduct, inventoryOfProduct) {
  const stockLossQty = parseFloat(physicalInventory || 0) - parseFloat(inventoryOfProduct || 0);
  return stockLossQty.toFixed(4);
}
function calculateStockLossQtyInkg(physicalInventory, inventoryProduct, inventoryOfProduct) {
  const actualChildWeight = parseFloat(getActualChildWeight(inventoryProduct.outer_size, inventoryProduct.weight, inventoryProduct.uom));
    return ((( physicalInventory || 0) / actualChildWeight) - inventoryOfProduct).toFixed(2);
}

function calculateStockLossInKg(stockLossQty, inventoryProduct) {
    const actualChildWeight = parseFloat(getActualChildWeight(inventoryProduct.outer_size, inventoryProduct.weight, inventoryProduct.uom));
    return (stockLossQty * actualChildWeight).toFixed(4);
}

function calculateStockValue(stockLossQty, productPrice, inventoryProduct) {
    const weight = parseFloat(getNumbersFromString(inventoryProduct.weight));
    return (stockLossQty * (productPrice * weight)).toFixed(4);
}

function calculatePhysicalInKg(row){
  const actualChildWeight = parseFloat(getActualChildWeight(row.outer_size, row.weight, row.uom));
  if (row.physical_inventory === undefined || row.physical_inventory === null) {
    return ""; 
  }
  return (actualChildWeight * row.physical_inventory).toFixed(4);
}


    const createStockCorrection = () => {

      let itemToSave = {};
      let isUpdate;
      try {
        if(targetWarehouse =="All"){
          throw new Error ("Please select a specific warehouse, 'All' is not allowed!")
        }
    
        inventoryMap.forEach((item, index) => {
          if (item.isEditable) {
            if ((item.physical_inventory == "" && item.physical_inventory !==0) || isNaN(item.physical_inventory)) {
              throw new Error("Please enter data for physical inventory");
            }else if(item.physical_inventory == item.system_inventory_kg){
              throw new Error("Physical inventory cant be equal to system iventory");
            }else if(item.physical_inventory < 0){
              throw new Error ("Physical inventory cant be in negative number")
            }
            

            if(item.stock_loss_gain_qty == 0){
              throw new Error ("No change in inventory cannot create a correction!")
            }

          const actualChildWeight =  parseFloat(getActualChildWeight(item.outer_size,item.weight,item.uom));
          const unapprovedItems = unApprovedCorrections.filter((unapproved) => unapproved.item_id == item.item_id  && unapproved.warehouse == targetWarehouse);
          isUpdate = unapprovedItems.length>0;

          if(isUpdate){
            itemToSave = {
              warehouse: targetWarehouse,
              item_id: item.item_id,
              quantity: item.stock_loss_gain_qty,
              inventory_after_shipped: item.inventory_after_shipped,
              inventory: item.inventory_after_shipped,
              id: unapprovedItems[0].id,
              created_by: JSON.parse(loggedUser)[0].email,
              updated_at: moment().format("YYYY-MM-DD HH:mm:ss"),
            };
          }else{
            itemToSave = {
              warehouse: targetWarehouse,
              item_id: item.item_id,
              quantity: item.stock_loss_gain_qty,
              inventory_after_shipped: item.inventory_after_shipped,
              inventory: item.inventory_after_shipped,
              stock_date:moment(new Date()).format("YYYY-MM-DD"),
              token : token,
              remarks: item.remarks || "",
              created_by: JSON.parse(loggedUser)[0].email,
              created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
            }
          }
          
          }
        });
      } catch (err) {
        return toast(`${err.message}`, {
          type: toast.TYPE.WARNING,
        });
      }

      let url = `${service_url}/api/${isUpdate ? `update-stock-correction?token=${token}` : "create-stock-correction"}`;  
      let method = isUpdate ? "PUT" : "POST";
      let headers = {
        "Content-Type": "application/json",
      };
      if (!isUpdate) {
        headers["x-access-token"] = sessionStorage.getItem("x-access-token");
      }

      fetch(url, {
        method: method,
        headers: headers,
        body: JSON.stringify(itemToSave),
      })
        .then((res) => res.json())
        .then((result) => {

          if (result.success == 1) {
            const action = isUpdate ? "updated" : "created";  
            toast(`${result.message}`, { type: toast.TYPE.SUCCESS });
            setIsModalOpen();
            setTimeout(function () {
              window.location.reload();
            }, 2000);
          } else {
            toast(`${result.message}`, { type: toast.TYPE.ERROR });
          }
        })
        .catch((err) => {
          toast(`Error in creating stock correction: ${err}`, {
            type: toast.TYPE.ERROR,
          });
        });
    };

  
  return (
    <div>
      <Modal opened={isModalOpen} size="98%" onClose={setIsModalOpen}>
        <h3 style={{ marginBottom: "20px" }} id="contained-modal-title-vcenter">
          Create Stock Correction
        </h3>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
            alignItems: "center",
          }}
        >
            <WarehouseSelectContainer>

          <MantineSelect
              label="Product type"
              placeholder="Select"
              value={selectedProductType}
              data={productTypes}
              onChange={setSelectedProductType}
              />
              </WarehouseSelectContainer>
              <Autocomplete
                value={searchTerm}
                onChange={handleSearchChange}
                data={filteredOptions}
                placeholder="Search Item Id/ Description"
                style={{ width: '35%' }}
                onItemSubmit={(item) => handleOptionSelect(item.value)} // Capture selected option
              />

         
          <WarehouseSelectContainer>
            <MantineSelect
              label="Warehouse"
              placeholder="Select"
              value={targetWarehouse}
              data={targetWarehouseList}
              onChange={setTargetWarehouse}
            />
          </WarehouseSelectContainer>
        </div>

        <div>
          {selectedItem && inventoryMap && inventoryMap.length > 0 ? (
            <>
              <div>
                <>
                  <table className="table table-striped">
                    <TransitRoutesTableHeader />
                    <TransitRoutesTableBody
                      inventoryMap={inventoryMap}
                      updateInventoryState={updateInventoryState}
                      calculateStockLossQty={calculateStockLossQty}
                      calculateStockLossInKg={calculateStockLossInKg}
                      calculateStockValue={calculateStockValue}
                      calculatePhysicalInKg={calculatePhysicalInKg}

                    />
                  </table>
                </>
              </div>
            </>
          ) : (
            selectedItem && (
              <div className="alert alert-warning text-center" role="alert">
                Item not available for selected product type
              </div>
            )
          )}

        </div>
        <BootStrapModal.Footer>
          {userType === "Admin" || userCity !== "Bhiwandi" ? (
            <Button
              style={{ float: "right" }}
              onClick={() => {
                createStockCorrection();
              }}
            >
              Create
            </Button>
          ) : null}

          <Button style={{ float: "right" }} onClick={props.onHide}>
            Close
          </Button>
        </BootStrapModal.Footer>
      </Modal>

      {backDrop ? <Backdrop parentLoadStatus={backDrop} /> : null}
    </div>
  );
}

const TransitRoutesTableHeader = () => {
  return (
    <thead className="thead-light">
      <tr>
        <th>Edit Row</th>
        <th>Item ID</th>
        <th>Item description</th>
        <th>System Inventory In QTY(KG)</th>
        <th>Physical QTY </th>
        <th>Stock Loss/ Gain In QTY(KG)</th>
        <th>Stock Loss/ Gain(Value)</th>
      </tr>
    </thead>
  );
};

const TransitRoutesTableBody = ({ inventoryMap, updateInventoryState,calculateStockLossQty,calculateStockLossInKg,calculateStockValue,calculatePhysicalInKg }) => {
  return (
    <>
      <tbody>
        {inventoryMap.map((row, index) => {

          return (
            <StockCorrectionTableRow
              key={index}
              index={index}
              row={{ ...row }}
              updateInventoryState={updateInventoryState}
              calculateStockLossQty={calculateStockLossQty}
              calculateStockLossInKg={calculateStockLossInKg}
              calculateStockValue={calculateStockValue}
              calculatePhysicalInKg = {calculatePhysicalInKg}
            />
          );
        })}
      </tbody>
    </>
  );
};

const  StockCorrectionTableRow = memo(
  (props) => {
    const { updateInventoryState, calculateStockLossInKg, calculateStockLossQty, calculateStockValue, calculatePhysicalInKg, index, row } = props;

    const [error, setError] = useState('');
    const [isPhysicalInventoryValid, setIsPhysicalInventoryValid] = useState(false);
    const [stockLossError, setStockLossError] = useState('');
    const [isStockLossValid, setIsStockLossValid] = useState(false);
    useEffect(() => {
      // Reset error when row is not editable
      if (!row.isEditable) {
        setError('');
        setIsPhysicalInventoryValid(false);
        setIsStockLossValid(false);
      }
    }, [row.isEditable]);
    

    const validatePhysicalInventory = (value) => {
      if (value === '') {
        return 'Please enter data for physical inventory';
      } else if ((value) == (row.inventory_after_shipped)) {
        return 'Physical inventory can\'t be equal to system inventory';
      } else if ((value) < 0) {
        return 'Physical inventory can\'t be negative';
      }
      return '';
    };

    const handlePhysicalInventoryChange = (value) => {
      if (value === "") {
        updateInventoryState("physical_inventory", 0, index);
        updateInventoryState("stock_loss_gain_qty", 0, index); 
        setError('');
        setIsPhysicalInventoryValid(false);
        return;
      }
      const errorMsg = validatePhysicalInventory(value);
      setError(errorMsg);
      updateInventoryState("physical_inventory", value, index);
      setIsPhysicalInventoryValid(errorMsg === '');
      if(errorMsg == ''){
        updateInventoryState("physical_inventory", value, index);
        const stockLossQty = calculateStockLossQty(value, row, row.inventory_after_shipped);
        updateInventoryState("stock_loss_gain_qty", stockLossQty, index);
      }

    };

    const calculatePhysicalFromLossGainQty = (lossGainQty) => {
      return (parseFloat(lossGainQty || 0) + parseFloat(row.inventory_after_shipped || 0)).toFixed(4);
    };

    const validateStockLossGain = (value) => {
      if (value === '' || value === 0) {
        return 'Stock loss/gain quantity cannot be zero';
      }
      return '';
    };
    const handleStockLossGainQtyChange = (value) => {
      if (value === "") {
        updateInventoryState("stock_loss_gain_qty", undefined, index);
        updateInventoryState("physical_inventory", undefined, index); // Reset physical_inventory
        return;
      }

      const errorMsg = validateStockLossGain(value);
      setStockLossError(errorMsg);
      setIsStockLossValid(errorMsg === '');
      
      if (errorMsg === '') {
        const physicalInventory = calculatePhysicalFromLossGainQty(value);
        updateInventoryState("physical_inventory", physicalInventory, index);
        updateInventoryState("stock_loss_gain_qty", value, index);
      }
     
    };


    const stock_Loss_Gain_QTY = row.isEditable && (isPhysicalInventoryValid || isStockLossValid) ? calculateStockLossQty(row.physical_inventory, row, row.inventory_after_shipped) : '';
    const physicalInventoryInKg =  row.isEditable && (isPhysicalInventoryValid || isStockLossValid) ? calculatePhysicalInKg(row):"";
    
    const stock_loss_gain_kg = row.isEditable && (isPhysicalInventoryValid || isStockLossValid) ? calculateStockLossInKg(stock_Loss_Gain_QTY, row) : '';
    const stockValue = row.isEditable && (isPhysicalInventoryValid || isStockLossValid) ? calculateStockValue(stock_Loss_Gain_QTY, row.landing_cost, row) : '';

    if (row.isEditable && isPhysicalInventoryValid) {
      updateInventoryState("stock_loss_gain_qty", stock_Loss_Gain_QTY, index);
    }
    
    const stockQty = (row.new_stock - row.inventory_after_shipped).toFixed(2);

    const stockLossGainStyle = (value) => ({
      color: value > 0 ? 'green' : 'red',
    });
    const getValidNumber = (num) => isNaN(Number(num)) || Number(num) === 0 ? "" : Number(num);

    return (
      <tr key={index}>
        <td style={{ textAlign: "center" }}>
          <Checkbox
            checked={row.isEditable}
            onChange={(event) => {
              updateInventoryState("isEditable", event.currentTarget.checked, index);
              if (!row.isEditable) {
                // Reset physical inventory value when row is not editable
                updateInventoryState("physical_inventory", "", index);
                setError('');
                setIsPhysicalInventoryValid(false);
              }
            }}
          />
        </td>
        <td style={{ fontWeight: "bold" }}>{row.item_id}</td>
        <td style={{ fontWeight: "bold" }}>{`${row.long_description || ""} - ${row.brand}`}</td>
        {/* <td>{`${row.system_inventory_kg} KG (${row.inventory_after_shipped})`}</td> */}
        <td>{row.inventory_after_shipped}{row.uom !== 'PC' && ` (${row.system_inventory_kg} KG)`}</td>
        <td>
          <div>
            <NumberInput
              disabled={!row.isEditable}
              value={row.isEditable ? getValidNumber(row.physical_inventory) : ""}
              precision={4}
              min={0}
              max={999999}
              onChange={(value) => {
                if (!value) {
                  updateInventoryState("physical_inventory", undefined, index);
                  updateInventoryState("stock_loss_gain_qty", undefined, index);
                  setIsPhysicalInventoryValid(true);
                } else {
                  handlePhysicalInventoryChange(value);
                }
              }}
              hideControls
            />
          {row.uom !== 'PC' && physicalInventoryInKg ? `${physicalInventoryInKg} Kg` : ''}
            {error && <Text color="red" size="sm">{error}</Text>}
          </div>
        </td>
        <td style={row.isEditable ? stockLossGainStyle(stock_Loss_Gain_QTY) : {}}>
          <NumberInput
              disabled={!row.isEditable}
              value={row.isEditable ? getValidNumber(row.stock_loss_gain_qty) : ""}
              precision={4}
              min={0}
              max={999999}
              onChange={(value) => {
                // if (!value && value !== 0) {
                if (!value) {
                  updateInventoryState("physical_inventory", undefined, index);
                  updateInventoryState("stock_loss_gain_qty", undefined, index);
                  setIsStockLossValid(true)
                } else {
                  handleStockLossGainQtyChange(value);
                }
              }}
              hideControls
            />
           {stockLossError && <Text color="red" size="sm">{stockLossError}</Text>}
           {row.uom !== 'PC' && stock_loss_gain_kg ? `${stock_loss_gain_kg} Kg` : ''} 
          {/* {row.isEditable && isPhysicalInventoryValid  ? `${stock_Loss_Gain_QTY} (${stock_loss_gain_kg} KG) ` : ''} */}
        </td>
        <td style={row.isEditable  && isPhysicalInventoryValid ? stockLossGainStyle(stockValue) : {}}>
          {row.isEditable ? stockValue : ''}
        </td>
      </tr>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps.row) === JSON.stringify(nextProps.row);
  }
);


export default StockCorrectionCreationModal;